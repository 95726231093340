import * as React from 'react';
// Customizable Area Start
import { createTheme } from '@material-ui/core/styles';
import { ViewProps } from './StripePayments.web';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import {
	styled,
	Divider,
	Box, 
	Button, 
	Typography, 
	TextField, 
	CircularProgress,
	InputAdornment,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
  } from "@material-ui/core";
import { Close as CloseIcon, ArrowBack as ArrowBackIcon, Check as CheckIcon } from '@material-ui/icons';
import CardDetailForm from '../../../components/src/AddNewPaymentCard';
import { CardListComponent } from '../../../components/src/CollectPayment';

import { master_card_icon, visa_dark_card_icon, success_icon } from "./assets";

export interface CardDataTypes {
	cardNumber: string;
	cardHolderName: string;
	expiryDate: string;
	cvv: string;
}

const CustomDivider = styled(Divider)({
	backgroundColor: "#64748B",
	height: '1px',
})

const DetailedScreenBox = styled(Box)({
	background: '#000',
	borderRadius: "0px 24px 0px",
});

const DetailedScreenDescription = styled(Box)({
	'& .MuiOutlinedInput-notchedOutline': {
	  borderColor: '#E2E8F0'
	},
	'& .MuiButtonBase-root': {
	  color: '#FFF'
	},
	"& input": {
	  color: '#FFF'
	},
	'& .MuiTypography-colorTextSecondary': {
	  color: '',
	  
	},
	"& .contentBox": {
	  width: '90%',
	  margin: 'auto',
	  padding: '16px 0',
	},
	"& .MuiInputBase-root": {
	  borderRadius: '8px',
	  // border: '1px solid #FFF'
	},
	'& .white-icon': {
	  color: '#FFF'
	},
	'& .amountRow': {
	  display: 'flex',
	  justifyContent: 'space-between',
	  alignItems: 'center',
	  color: '#FFF',
	},
	'& .amountRow .screenName': {
	  color: '#FFF',
	  fontSize: '18px',
	  fontWeight: 700,
	},
	'& .amountRow .regionName': {
	  color: '#94A3B8',
	  fontSize: '12px',
	},
	'& .amountRow .totalTitle': {
	  color: '#94A3B8',
	},
	'& .amountRow .totalTitle1': {
	  color: '#94A3B8',
	  fontSize: '18px',
	},
	'& .amountRow .totalAmount': {
	  fontWeight: 700,
	},
});
  
const DetailedScreenTitle = styled(Box)({
	padding: '0 20px',
	'& .detailTitle': {
	  color: '#fff',
	  fontSize: '20px',
	  fontWeight: 700,
	  lineHeight: '28px',
	  textAlign: 'left',
	},
	'& .detailRegion': {
	  color: '#fff',
	  fontSize: '14px',
	  fontWeight: 400,
	  textAlign: 'left',
	  paragraph: '12px',
	},
  });
  
const DetailedScreenActions = styled(Box)({
	marginTop: '20px',
  });
  
const GoBackToScreenButton = styled(Button)({
	padding: '16px 109px 16px 108px',
	background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
	gap: '8px',
	borderRadius: '8px',
	display: 'flex',
	bottom: '15px',
	margin: 'auto',
	fontWeight: 'bold',
	fontSize: '16px',
	width: '90%'
  })

// Customizable Area End

const StripePaymentsView: React.FC<ViewProps> = ({
	// Customizable Area Start
	testID,
	stripePromise,
	stripeClientSecret,
	errorString,
	setOrderNumber,
	orderNumber,
	actionResult,
	stripeInitialised,
	isInStripeCallback,
	onHandleSubmit,
	submitOrderNumberButtonViewProps,
	submitPaymentButtonViewProps,
	loadingViewProps,
	orderIdViewProps,
	stripeMessageViewProps,
	// Customizable Area End
}) => {
	const Render: React.FC<ViewProps> = ({
		testID,
		errorString,
		setOrderNumber,
		orderNumber,
		actionResult,
		stripeInitialised,
		isInStripeCallback,
		onHandleSubmit,
		submitOrderNumberButtonViewProps,
		submitPaymentButtonViewProps,
		orderIdViewProps,
		stripeMessageViewProps,
	}) => {
		// Customizable Area Start
		const {
			isChangeCard,
			savedStripCardList,
			selectedCardDataDetails,
			handleIsChangeCard,
			uploadedScreenDetails,
			isAddCardForm,
			incorrectCardNumber,
			cardSuccessMessage,
			setIsCardForm,
			paymentLoadingScreen,
			getCardNumber,
			getCardIconSrc,
			handleCardItemFromList,
			handleCardSubmit,
			handlePaymentDetailDrawer,
			getTotalDays,
			cardErrorMessage,
			...props
		} = orderIdViewProps;
		const handleCardFormOpen = () => {
			setIsCardForm(true)
		}
		const getCardList = () => {
			return savedStripCardList
		}
		const stripe = useStripe();
		const stripeElements = useElements();

		if(isAddCardForm) {
			return (
				<DetailedScreenBox>
					<DetailedScreenDescription>
						<CardDetailForm
							data-test-id="card_detail_form"
							getCardIconSrc={getCardIconSrc}
							handleCardSubmit={handleCardSubmit}
							selectedCardDataDetails={selectedCardDataDetails}
							incorrectCardNumber={incorrectCardNumber}
							cardErrorMessage={cardErrorMessage}
						/>
					</DetailedScreenDescription>
				</DetailedScreenBox>
			);
		}

		// Customizable Area End

		return (
			// Customizable Area Start
			<DetailedScreenBox>
				{isChangeCard ? (
					<IconButton style={{color:'#FFF'}} onClick={handleIsChangeCard}>
						<ArrowBackIcon />
					</IconButton>
					): (
					<Box style={{display: 'flex', justifyContent: 'end'}}>
						<IconButton style={{color:'#FFF'}} onClick={handlePaymentDetailDrawer}>
						<CloseIcon />
						</IconButton>
					</Box>
				)}
				{!isChangeCard && <DetailedScreenTitle>
				<Typography className="detailTitle">{"Payment Details"}</Typography>
				</DetailedScreenTitle>}
				<DetailedScreenDescription>
				{!isChangeCard && (
					<Box className="contentBox">
					<div className={'amountRow'}>
						<div>
						<Typography variant="body1" className="screenName">{uploadedScreenDetails.screenSelected?.attributes?.screen_name}</Typography>
						<Typography variant="body1" className="regionName">{uploadedScreenDetails.screenSelected?.attributes?.region}</Typography>
						</div>
					</div>
					<CustomDivider style={{margin: '20px 0'}} />
	
					<div className={'amountRow'}>
						<Typography variant="body1" style={{fontWeight: 700}}>Card number</Typography>
						<Typography data-test-id="change" variant="body1" style={{color: '#F00', cursor: 'pointer'}} onClick={handleIsChangeCard}>Change</Typography>
					</div>
					<TextField
						data-test-id="cardNumber"
						fullWidth
						variant="outlined"
						style={{border: '1px solid #FFF', borderRadius: '8px'}}
						placeholder={getCardNumber()}
						value={getCardNumber()}
						disabled
						InputProps={{
						endAdornment:
							<InputAdornment position="end">
							<IconButton
								aria-label="toggle visibility"
								edge="end"
							>
								<img className="cardIcon" src={getCardIconSrc(selectedCardDataDetails)} />
							</IconButton>
							</InputAdornment>
						}}
					/>
					<CustomDivider style={{margin: '20px 0'}} />
					<div style={{margin: '16px 0', gap: '16px'}}>
						<div className={'amountRow'}>
						<Typography variant="body1" className="totalTitle">{`${uploadedScreenDetails.screenSelected?.attributes?.screen_name.substring(0, 15)}...`} {`x${getTotalDays()}`}</Typography>
						<Typography variant="body1" className="totalAmount">${uploadedScreenDetails.screenSelected?.attributes?.price}</Typography>
						</div>
						<div className={'amountRow'} style={{marginTop: '16px'}}>
						<Typography variant="h6" className="totalTitle1">Total Amount</Typography>
						<Typography variant="h6" className="totalAmount">${uploadedScreenDetails.amount}</Typography>
						</div>
					</div>
					</Box>
				)}
				{isChangeCard && (
					<Box className="contentBox">
						<Typography variant="h3" style={webStyle.paymentCardDetailTitle}>{"Payment Method"}</Typography>
					<CustomDivider style={{margin: '20px 0', height: '1px'}} />
						<Typography variant="body1" style={webStyle.selectCardTest}>{"Select a card"}</Typography>
					<CustomDivider style={{margin: '10px 0 0', height: '1px'}} />
	
					{Array.isArray(getCardList()) && getCardList().length === 0 ? (
						<Box sx={webStyle.cardErrorBox} data-test-id="noCardsAvailable"> No cards are available. Please click on add payment method</Box>
					) : (
						Array.isArray(getCardList()) && getCardList().map((card: any, index: number) => {
						return (
							<ListItem
								button
								data-test-id="edit & delete"
								style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}
								onClick={() => handleCardItemFromList(card)}
								key={`${card.id}${index}`}
								>
								<ListItemIcon style={{ marginRight: '8px', minWidth: 'initial' }} data-test-id='card_icon'>
									<img src={card?.attributes?.card?.display_brand === "visa" ? visa_dark_card_icon : master_card_icon} />
								</ListItemIcon>
								<ListItemText style={{ fontSize: '14px', fontWeight: 400, color: '#ffffff' }} data-test-id='card_number'>
									<span style={{ padding: '0' }}>**** {card?.attributes?.card?.last4}</span><br />
								</ListItemText>
								{selectedCardDataDetails.id === card?.id && (
									<ListItemIcon 
										style={{ minWidth: '10px', color: '#CBD5E1' }}
										key={`${card.id}${index}icon`} 
									><CheckIcon /></ListItemIcon>
								)}
							</ListItem>
						);
						})
					)}
					<CardListComponent 
						cardSuccessMessage={cardSuccessMessage}
						success_icon={success_icon}
						getCardList={getCardList}
						handleCardFormOpen={handleCardFormOpen}
					/>
					</Box>
				)}
				</DetailedScreenDescription>
				{!isChangeCard && (
					<DetailedScreenActions>
						<GoBackToScreenButton 
							disabled={!savedStripCardList.length || uploadedScreenDetails.loader}
							data-test-id="submit-payment" 
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								event.preventDefault();
								onHandleSubmit(event, stripe, stripeElements)
							}}
						>
							{"Pay now"}
						</GoBackToScreenButton>
					</DetailedScreenActions>
				)}
				{paymentLoadingScreen && <div style={{...webStyle.loaderWrapper, position: 'absolute'}}>
					<CircularProgress />
				</div>}
			</DetailedScreenBox>
		);
		// Customizable Area End
	};

	const viewProps: ViewProps = {
		testID: testID,
		errorString,
		stripePromise: stripePromise,
		stripeClientSecret: stripeClientSecret,
		setOrderNumber: setOrderNumber,
		orderNumber: orderNumber,
		actionResult: actionResult,
		stripeInitialised: stripeInitialised,
		isInStripeCallback: isInStripeCallback,
		onHandleSubmit: onHandleSubmit,
		submitOrderNumberButtonViewProps,
		submitPaymentButtonViewProps,
		loadingViewProps,
		orderIdViewProps,
		stripeMessageViewProps,
	};

	return (
		<Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }} key={stripeClientSecret}>
			<Render {...viewProps} />
		</Elements>
	);
};

const theme = createTheme({
	// Customizable Area Start
	overrides: {
		MuiFormControlLabel: {
			label: {
				width: '100%',
			},
		},
	},
	// Customizable Area End
});

const webStyle = {
	// Customizable Area Start
	loaderWrapper: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		'align-items': 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 1,
	},
	successStatusWrapper: {
    position: "absolute",
    bottom: "50px",
    width: "90%",
    display: "flex",
    justifyContent: "center",
		margin: 'auto'
  },
	successStatus: {
    maxWidth: "300px",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px",
    display: "flex",
    margin: "auto",
    position: 'fixed',
    bottom: "20px",
    width: '80%',
    borderRadius: "8px",
    backgroundColor: "#fff",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
  },
	mainWrapper: {
		paddingTop: '32px',
		paddingBottom: '32px',
		display: 'flex',
		fontFamily: 'Roboto-Medium',
		flexDirection: 'column',
		background: '#fff',
		gap: '1rem',
	},
	horizontalWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '2rem',
	},
	submitButton: {
		backgroundColor: '#6200EE',
		color: '#fff',
	},
	cancelButton: {
		backgroundColor: '#FF0000',
	},
	cardErrorBox: {
		borderLeft: "4px solid #DC2626",
		fontSize: "12px",
		lineHeight: "18px",
		backgroundColor: "#FEE2E2",
		color: "#DC2626",
		padding: "15px 16px",
		margin: "15px 0",
		width: '100%',
		boxSizing: "border-box",
	  },
	  paymentCardDetailTitle: {
		fontSize: '24px',
		fontWeight: 700,
		color: '#FFF'
	  },
	  selectCardTest: {
		fontWeight: 700,
		color: '#FFF',
		fontSize: '14px',
		'text-transform': 'uppercase',
		margin: '0px 16px',
	  },
	// Customizable Area End
};

export default StripePaymentsView;
