import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { GenericApiCall, checkTokenError } from "../../utilities/src/GenericApiCall";
import { getToken } from "../../utilities/src/RegExRequired";
import moment from "moment";
import { get } from '../../../components/src/commonTable/table';
import React from "react";

type objectDataType =  {[key:string]: string | {[key:string]: string | number | null}}

interface IStyleTypes {
  availableAlert: objectDataType;
  unavailableAlert: objectDataType;
  activeAlert: objectDataType;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadedFilesAll: any[] ;
  uploadedFiles: any[] ;
  uploadingFile:string | any;
  uploadProgress: number;
  anchorEl: HTMLElement | null;
  selectedFileIndex: number | null;
  fileName:any;
  fileSize:string;
  duration:string;
  errorMsg:{
    type:"error"|"warning"|"info"|"success"|"",
    msg:string
  },  itemToDelete:number;
  screenSelected:any;
  allScreens:any;
  showErrMsg: boolean;
  mediaErr:string;
  progress:any[],
  status:any[],
  failedFiles:any[],
  screenBookings: any;
  bookingDatesOriginal: any;
  bookedDates: any;
  bookedSelectedId: any;
  startDate:any;
  endDate: any;
  isPaymentDrawer: boolean;
  savedCardList: [];
  errMessage: string;
  anchorEl2: any;
  amount: number;
  recentFiles: any;
  recentFilesAll: any;
  openScannedModal: boolean;
  openDescModal:  boolean;
  thumbnail: any;
  durationValid: boolean;
  pixelValid: boolean;
  file: any;
  userDetails: any;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  preview?: string;
  // Customizable Area End
}

export default class Uploadmedia3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScreensApiCallId = '';
  getScreenStatusApiCallId = '';
  getScreenFilesApiCallId = '';
  getSavedCardListId = '';
  getScreenBooklingsApiCallId = '';
  getConvertedVideoApiCallId ='';
  postCreationApiCallId = '';
  getScreenFilesLengthApiCallId = '';
  uploadMediaApiCallId = '';
  getNotficationDataApiCallId = '';
  errorMsgInterval: any;
  canvasRef: any;
  uploadRef: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.canvasRef = React.createRef();
    this.uploadRef = React.createRef();

    this.state = {
      file: '',
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadedFilesAll: [],
      uploadedFiles: [],
    uploadingFile: null,
    uploadProgress: 10,
    anchorEl: null,
    selectedFileIndex: null,
    fileName: null,
    fileSize: "",
    duration: "",
    errorMsg:{
      type:'',
      msg:''
    },    itemToDelete:0,
    screenSelected: "",
    allScreens:[],
    showErrMsg: false,
    mediaErr:'',
    status:[],
    progress:[],
    failedFiles:[],
    screenBookings: [],
    bookingDatesOriginal: [],
    bookedSelectedId: "",
    bookedDates: [],
    startDate: "",
    endDate: "",
    isPaymentDrawer: false,
    savedCardList: [],
    errMessage: "",
    anchorEl2: null,
    amount: 0,
    recentFiles: "",
    recentFilesAll: [],
    openScannedModal: false,
    openDescModal: false,
    thumbnail: "",
    durationValid: false,
    pixelValid: false,
    userDetails: '',
    loader: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(getName(MessageEnum.NavigationPayLoadMessage) == message.id){
      let data = message.getData("file");
      this.setState({ file: data });
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );    

    if (apiRequestCallId != null) {
      if(apiRequestCallId === this.getScreensApiCallId){
        this.handleScreensApiresponse(responseJson)
      }
      else if(apiRequestCallId === this.getScreenStatusApiCallId){
        this.handleScreenStatusApiresponse(responseJson)
      }
      else if(apiRequestCallId === this.getScreenBooklingsApiCallId){
        this.handleScreenBookingApiresponse(responseJson)
      }
      else if(apiRequestCallId === this.getSavedCardListId) {
        this.handleSavedCardApiResponse(responseJson);
      }
      else if (apiRequestCallId === this.getScreenFilesApiCallId){
        this.handleScreenFilesApiResponse(responseJson);
      }
      else if (apiRequestCallId === this.postCreationApiCallId){
        this.handleCreatePostApiresponse(responseJson)
      } 
      else if (apiRequestCallId === this.getNotficationDataApiCallId) {
        this.handleUserDetailsApiresponse(responseJson)        
      }
      else if (apiRequestCallId === this.getScreenFilesLengthApiCallId) {
        this.handleMediaCountApiresponse(responseJson);
      } else if (apiRequestCallId === this.getConvertedVideoApiCallId) {
        this.handleConvertedMediaApiresponse(responseJson)        
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  handleUploadClick = () => {
    this.uploadRef.current.click();
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  handleOpenDescModal = () => {
    this.setState({ openDescModal: true });
  }

  handleDescModalClose = () => {
    this.setState({ openDescModal: false });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleScreensApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    } else {
      // const excludeDates = this.handleExcludeDates(responseJson.data[0]);
      let scannedQrValue:any = localStorage.getItem('scannedQrValue');
      const selectedScreen = localStorage.getItem('selectedScreen');
      const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
      const getAvailableScreens = responseJson?.data?.filter((item: any) => item.attributes.status !== "unavailable");
      const navigatedFromUrlScreen = this.props.navigation.getParam('screenid');

      if(navigatedFromUrlScreen){
        const getScanned = responseJson.data.filter((item: any) => item.id === navigatedFromUrlScreen);
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === getScanned[0]?.attributes.media_format.toLowerCase());
        this.getScreensBookings(getScanned[0]);  
        this.setState({ allScreens: getAvailableScreens, screenSelected: getScanned[0], openScannedModal: true, amount: getScanned[0].attributes.price, recentFiles: recentFiles.splice(0, 3) })
      } else if(selectedActiveScreen){
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === JSON.parse(selectedActiveScreen).attributes.media_format.toLowerCase());
        this.getScreensBookings(JSON.parse(selectedActiveScreen));
        this.setState({ allScreens: getAvailableScreens, screenSelected: JSON.parse(selectedActiveScreen), amount: JSON.parse(selectedActiveScreen).attributes.price, recentFiles: recentFiles.splice(0, 3) });
      } else if(scannedQrValue){
        const getScanned = responseJson.data.filter((item: any) => item.id === scannedQrValue);
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === getScanned[0].attributes.media_format.toLowerCase());
        this.getScreensBookings(getScanned[0]);  
        this.setState({ allScreens: getAvailableScreens, screenSelected: getScanned[0], openScannedModal: true, amount: getScanned[0].attributes.price, recentFiles: recentFiles.splice(0, 3) })
      } else if(selectedScreen){
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === JSON.parse(selectedScreen).attributes.media_format.toLowerCase());
        this.getScreensBookings(JSON.parse(selectedScreen));        
        this.setState({ allScreens: getAvailableScreens, screenSelected: JSON.parse(selectedScreen), amount: JSON.parse(selectedScreen).attributes.price, recentFiles: recentFiles.splice(0, 3) });
      } else {
        this.getScreensBookings(getAvailableScreens[0]);
        this.setState({ allScreens: getAvailableScreens });
      }
    }
  }

  handleScreenStatusApiresponse = (responseJson:any) => {
    this.setState({ loader: false });
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    } else {
      const getScanned = responseJson.data.filter((item: any) => item.id === this.state.screenSelected.id);
      if(getScanned[0]?.attributes.status !== "unavailable"){
        this.handlePaymentDrawer()
      } else {
        this.setState({ errMessage: "The selected screen is no longer available. Please choose a different screen before proceeding to payment.", showErrMsg: true });
        setTimeout(() => {
          this.setState({ errMessage: "", showErrMsg: false });
        }, 3000);
      }
    }
  }

  getFieldValue = (keyValue: string): string => {
    return get(this.state.screenSelected, `attributes.${keyValue}`)
  }

  getChipColor = (styles: IStyleTypes) => {
    const status = this.getFieldValue('status');
    if(status === 'unavailable') return styles.unavailableAlert;
    if(status === 'available') return styles.availableAlert;
    if(status === 'active') return styles.activeAlert;
    return {display: 'none'}
  };

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleScreenBookingApiresponse(responseJson:any){
    if(responseJson.errors){
      this.setState({
        errorMsg: { type: 'error', msg: responseJson.errors[0] },
        errMessage: responseJson.errors[0],
        showErrMsg: true
      });
    }
    else{
      const { dateArray, originalDateArray } = this.handleExcludeDates(responseJson.dates);
      this.setState({ screenBookings: dateArray, bookingDatesOriginal: originalDateArray, bookedDates: responseJson.dates })
    }
  };

  handleSavedCardApiResponse(responseJson:any){
    const excludeDates = responseJson?.data;
    this.setState({ savedCardList: excludeDates });
  };

  handleScreenFilesApiResponse(responseJson:any){
    const recentFiles = responseJson?.data?.filter((file: any) => file.attributes.status !== "rejected");
    
    this.setState({ recentFilesAll: recentFiles || [] });
  };

  handleUserDetailsApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      this.setState({ userDetails: responseJson.data })
    }
  }
  handleConvertedMediaApiresponse(responseJson:any){
    let status = this.state.status;
    status[this.state.status?.indexOf('uploading')] = 'done'
    this.setState({status})

    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      if(responseJson.converted_image_url){
        this.setState({ loader: false, uploadedFilesAll: [{ converted_image_url: responseJson.converted_image_url }] })
      } else if(responseJson.mp4_url) {
        this.createThumbnail(responseJson.mp4_url);
        this.setState({ loader: false, uploadedFilesAll: [{ mp4_url: responseJson.mp4_url, duration: responseJson.duration }	] })
      }
    }
  }

  handleMediaCountApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      if (!responseJson?.booking_count || responseJson?.booking_count < 30) {
        this.navigateToOtherScreen('Uploadmedia3', this.state);
      } else {
        this.setState({
          errMessage: "Max limit reached for these dates.",
          showErrMsg: true,
        })

        setTimeout(() => {
          this.setState({
            errMessage: "",
            showErrMsg: false,
          })
        }, 5000);
      }
    }
  }

  clearSelectedData = () => {
    localStorage.removeItem('uploadImage')
    localStorage.removeItem('PaymentScreen')
    localStorage.removeItem('scannedQrValue');
    localStorage.removeItem('selectedScreen');
    localStorage.removeItem('selectedActiveScreen');
    localStorage.removeItem('uploadedImages');
  }

  handleCreatePostApiresponse(responseJson:any){
    this.setState({ loader: false });
    if(responseJson.errors){
      this.setState({ errMessage: "Failed to upload!", showErrMsg: true });
      this.handleErrorResponse(responseJson);
      this.handlePaymentDrawer();
    } else{
      localStorage.removeItem('uploadImage')
      localStorage.removeItem('PaymentScreen')
      localStorage.removeItem('scannedQrValue');
      localStorage.removeItem('selectedScreen');
      localStorage.removeItem('selectedActiveScreen');
      localStorage.removeItem('uploadedImages');
      if(responseJson?.message.includes("uploaded successfully")){
        this.handlePaymentDrawer()
        this.navigateToOtherScreen('PaymentSuccess');
      }
    }
  }

  getUserDetailsValue = () => {
    let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.notificationsDataApiEndPoint, token)
    this.getNotficationDataApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  handleDatesForInteractive = (date: any, start: any, end: any) => {
    const dateArray: any = [];
    const originalDateArray: any = [];

    if(date.user_id != this.state.userDetails.id){
      while (start <= end) {
        dateArray.push(new Date(start));
        originalDateArray.push(moment(new Date(start)).format("YYYY-MM-DD"))
        
        start.setDate(start.getDate() + 1);
      }
    }

    return { dateArray, originalDateArray };
  }

  handleExcludeDates(dates: any){
    if(dates){
      const dateArray: any = [];
      const originalDateArray: any = [];
      dates.map((date: any) => {
        const start = new Date(date.start_date);
        const end = new Date(date.end_date);
        
        const d: any = this.handleDatesForInteractive(date, start, end)
        dateArray.push(...d.dateArray);
        originalDateArray.push(...d.originalDateArray);
      })

      return { dateArray, originalDateArray }
    } else {
      return { dateArray: [], originalDateArray: [] };
    }
  }

  isBookedExist = (start: any, end: any, dateArray: any) => {
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      // Format the date to a string
      const dateStr = d.toISOString().split('T')[0];
      
      // Check if the date is in the array
      if (dateArray.includes(dateStr)) {
        return { isBooked: true, date: dateStr };
      }
    }
    return { isBooked: false };
  }

  findDateRecord(selectedDate: any) {
    const selected = new Date(selectedDate);
  
    return this.state.bookedDates.find((date: any) => {
      const startDate = new Date(date.start_date);
      const endDate = new Date(date.end_date);
  
      return selected >= startDate && selected <= endDate;
    });
  }

  getMaxDate() {
    return this.state.screenSelected?.attributes?.interactive ? new Date() : null;
  }

  getExcludedDates() {
    return this.state.screenSelected?.attributes?.interactive ? [] : this.state.screenBookings;
  }

  handleDateChange(date: string, value: any){
    let dateValue = value;

    const selected = this.findDateRecord(dateValue);

    if (selected?.id) {
      const data = this.handleExcludeDates([
        {
          start_date: selected.start_date,
          end_date: selected.end_date
        }
      ])

      this.setState({
        ...this.state,
        startDate: selected.start_date,
        endDate: selected.end_date,
        bookedSelectedId: selected.id,
        amount: this.state.screenSelected.attributes.price * data.originalDateArray.length
      });
    } else {
      if (date === "startDate") {
        this.setState({ ...this.state, startDate: value, endDate: '', bookedSelectedId: '', amount: this.state.screenSelected.attributes.price });
      } else {
        const { startDate } = this.state;
        const result = this.isBookedExist(new Date(moment(startDate).format("YYYY-MM-DD")), value, this.state.bookingDatesOriginal);

        if (result.isBooked) {
          this.setState({ ...this.state, showErrMsg: true, endDate: "", errMessage: `${result.date} is booked, please select different dates` });
          setTimeout(() => {
            this.setState({ ...this.state, showErrMsg: false, errMessage: '' });
          }, 3000);
        } else {
          const data = this.handleExcludeDates([
            {
              start_date: moment(new Date(this.state.startDate)).format("YYYY-MM-DD"),
              end_date: moment(value).format("YYYY-MM-DD")
            }
          ])
          
          const totalAmount = this.state.screenSelected.attributes.price * data.originalDateArray.length;
          this.setState({ endDate: value, amount: parseFloat(totalAmount.toFixed(2)), bookedSelectedId: "" });
        }
      }
    }
  }

  handleErrorResponse = (responseJson: any) => {
    let errorMsg = checkTokenError(responseJson)
    if (errorMsg.type == 'token') {
      this.setState({
        errorMsg: { type: 'error', msg: errorMsg.msg },
        showErrMsg: true
      })
    }
    else {
      let msg: string[] = Object.values(responseJson.errors[0]);
      this.setState({
        errorMsg: { type: 'error', msg: msg[0] },
        showErrMsg: true
      });
    }

    // Clear previous interval if it exists
    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }
    // Set new interval to hide error message
    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });
      if(errorMsg.type == 'token'){
        this.tokenExpired()
      }
    }, 5000);
  }

  handlePaymentDrawer = () => {
    this.setState({isPaymentDrawer: !this.state.isPaymentDrawer})
  }

  getMediaType = () => {
    return this.state.screenSelected && this.state.screenSelected.attributes.media_format[0].toUpperCase() + this.state.screenSelected.attributes.media_format.slice(1) || "-";
  }

  getScreenSelected = () => {
    return this.state.screenSelected && this.state.screenSelected.attributes.screen_name || "Select Screen";
  }

  isDateDisabled = () => {
    return this.getPathDetails() === "/PaymentPreview" || !this.state.screenSelected?.id;
  }

  handlePostCreation = () => {
    this.setState({ loader: true });
    let token = getToken();
    token = token ? token : ''

    let bodyData = new FormData();
    bodyData.append("[screen_id]", this.state.screenSelected.id);

    if(this.state.bookedSelectedId){
      bodyData.append("[booking_id]", this.state.bookedSelectedId);
    } else {
      bodyData.append("[start_date]", `${moment(this.state.startDate).format("DD-MM-YYYY")}`);
      bodyData.append("[end_date]", `${moment(this.state.endDate).format("DD-MM-YYYY")}`);
    }

    if(this.state.fileName.name === this.state.file?.name){
      if(this.state.file.type.includes('video')){
        bodyData.append("files[]", this.state.uploadedFilesAll[0].mp4_url );
        bodyData.append("[video_duration]", this.state.uploadedFilesAll[0]?.duration + '');
      } else {
        bodyData.append("files[]", this.state.uploadedFilesAll[0].converted_image_url );
      }
      bodyData.append("[recent]", 'false');
    } else {
      bodyData.append("files[]", this.state.fileName.preview);
      bodyData.append("[screen_file_id]", this.state.fileName.screen_file_id);
      bodyData.append("[recent]", 'true');
    }

    let requestMesg = GenericApiCall('', configJSON.postCreationAPiMethod, configJSON.postCreationApiEndPoint, token, bodyData)
    this.postCreationApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg);
  }

  manageFileUpload = (e: any,indexx:number) => {
    if(this.state.uploadedFiles.length > 0){
      this.setState({ errMessage: "Only 1 media file can be upload.", showErrMsg: true })
      
      setTimeout(() => {
        this.setState({ showErrMsg: false, errMessage: "" });
      }, 3000);
      return;
    }
    this.setState({ showErrMsg: false, mediaErr: "" });

    this.handleFileUpload(e, indexx);
  }

  validateImageDimensions = (file: any, handleMediaPixel: any) => {
    const img: any = new Image();
    const objectURL = URL.createObjectURL(file);

    img.src = objectURL;
    img.onload = function() {
          if((img.width && img.width < 50) || (img.height && img.height < 50)) {
            handleMediaPixel(true);
          } else {
            handleMediaPixel(false);
          }
    };
    img.onload(file);
  }

  getConvertedVideoApi=(File: any, position: number, type: any)=>{
    let status = this.state.status;
    let progress = this.state.progress;
    status[position] = 'uploading';
    progress[position] = 0;
    this.setState({status, progress})

    const interval = setInterval(() => {
      this.setState((prevData) => {
        const newProgress = Math.min(prevData.progress[position] + 10, 100);
        if (newProgress === 100) {
          clearInterval(interval);
        }
        prevData.progress[position] = newProgress;
        return {...prevData };
      });
    }, 200);

    let token:any = getToken()
    let bodyData = new FormData();
    if(type.includes('video')){
      bodyData.append("[type]", "video");
      bodyData.append("video", File);
    } else {
      bodyData.append("[type]", "image");
      bodyData.append("image", File);
    }
    let requestMesg = GenericApiCall('', configJSON.getConvertedVideoApi, configJSON.getConvertedVideoApiURL, token, bodyData);
    this.getConvertedVideoApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg);
  }

  getType = (file: any) => {
    const ext = file.name.toLowerCase().slice(file.name.lastIndexOf('.') + 1);
    return (file.type.includes('video') || file.type.includes('image') ) ? file.type : this.mediaType(ext);
  }

  handleFileUpload = (e: any,indexx:number) => {
    let targetFiles:any = e.target.files;
    let images = Array.from(targetFiles).map((file:any)=>{
      let preview: any = URL.createObjectURL(file);
      let width = 0;
      let height = 0;
      let handleMediaPixel = (value: boolean) => this.setState({ pixelValid: value });

      if(file.type.includes('video')){
        URL.createObjectURL(file)
        const fileURL = URL.createObjectURL(file);
        this.createThumbnail(fileURL);

        const video = document.createElement('video');
        video.src = fileURL;
        
        video.onloadeddata = () => {
          width = video.videoWidth;
          height = video.videoHeight;
            if((width && width < 50) || (height && height < 50)) {
              handleMediaPixel(true);
            } else {
              handleMediaPixel(false);
            }
            if(video?.duration > 60) {
              this.setState({ durationValid: true })
            }
        }

        video.onloadeddata(e);

        const reader: any = new FileReader();
        reader.onload = () => {
          preview = reader.result
        };
        reader.readAsDataURL(file);
      } else if (file.type.includes('image')) {
        this.validateImageDimensions(file, handleMediaPixel);
      }
      return {
        name:file.name,
        size:file.size,
        type: this.getType(file),
        preview:preview,
        height: height,
        width: width,
      }
    })
    images.forEach((image,inde)=>{
      let position = indexx + inde;
      let inter = setInterval(()=>{
        let {uploadedFiles,status,progress,failedFiles} = this.state;
        // uploadedFiles[position] = image
        
        if(status[position] === 'fail'){
          progress[position] = 100;
        } else if(status[position] !== 'done'){
          status[position] = 'uploading';
          progress[position] = 100;
        }
        if(progress[position] === 100){
          const format = image.name.toLowerCase().slice(image.name.lastIndexOf('.') + 1);
          
          if(!image.type.includes(this.state.screenSelected.attributes.media_format.toLowerCase())){
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaType();
          } else if(image.size  / (1024 * 1024) > this.fetchMaxSizeMedia(this.state.screenSelected.attributes.media_format.toLowerCase())){
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaSize()
          } else if(this.checkMediaFormat(format)) {
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaFormat();
          } else if(this.state.durationValid) {
            status[position] = 'fail';
            failedFiles.push(1);
            this.setState({mediaErr: 'The uploaded media duration should be less than 60 Seconds'});
          } else if(this.state.pixelValid) {
            status[position] = 'fail';
            failedFiles.push(1);
            this.handleMediaPixel();
          } else {
            uploadedFiles[position] = image;
            failedFiles = [];
            this.getConvertedVideoApi(targetFiles[0], position, image.type);
          }
          
          clearInterval(inter);
        }
        this.setState({file: targetFiles[0], progress,status,failedFiles,uploadedFiles, fileName: uploadedFiles[0]})

      },500)
    })
  };

  uploadContinueButtonDisabled = () => {
    return this.state.uploadedFiles.length === 0 || this.state.failedFiles.length > 0 || this.state.progress[0] !== 100
  };

  uploadContinueButtonPreviewDisabled = () => {
    return !this.state?.fileName?.screen_file_id && this.state.failedFiles.length > 0;
  };

  uploadFileDisabled = () => {
    return this.state.uploadedFiles.length > 0 
    // || this.state.failedFiles.length > 0
  };

  handleMediaSize = () => {
    !this.state.mediaErr && this.setState({ mediaErr: `The uploaded media size should be less than ${this.fetchMaxSizeMedia(this.state.screenSelected.attributes.media_format.toLowerCase())}MB.` })
  }

  handleMediaType = () => {
    !this.state.mediaErr && this.setState({ mediaErr: `Selected screen accepts only ${this.state.screenSelected.attributes.media_format.toLowerCase()}.` })
  }

  handleMediaFormat = () => {
    !this.state.mediaErr && this.setState({ mediaErr:"The uploaded media doesn't have supported format." })
  }

  handleMediaPixel = () => {
    !this.state.mediaErr && this.setState({ 
      mediaErr:"Files with height and width less than 50 pixels should not be allowed to upload." 
    })
  }

  createThumbnail = (videoSrc: any) => {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous'; 
    video.src = videoSrc;
    video.onloadeddata = () => {
      const canvas = this.canvasRef.current;
      const context = canvas?.getContext?.('2d');
      video.currentTime = 5; // Capture a frame at 5 seconds
      if(Math.floor(video.duration) > 60) {
        this.setState({
          durationValid: true,
        });
      } else {
        this.setState({
          durationValid: false,
        });
      }
      video.onseeked = () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/png');
        this.setState({ thumbnail: dataURL })
        URL.revokeObjectURL(videoSrc); // Free up memory
      };
    };

    video.onloadeddata(videoSrc);
  }

  isScreenUnAvailable = () => {
    return this?.state?.screenSelected?.attributes?.status === "unavailable" || this.getPathDetails() === "/PaymentPreview" ? true : false;
  }

  fetchMaxSizeMedia = (type: string) => {
    switch (type) {
      case "video":
        return 100;
      case "image":
        return 12;
      default:
        return 12;
    }
  };

  mediaType = (format: any) => {
    switch(format){
      case'jpg':
      case'bmp':
      case'eps':
      case'gif':
      case'heic':
      case'ico':
      case'jpeg':
      case'nef':
      case'orf':
      case'png':
      case'psd':
      case'svg':
      case'jpeg 2000':
      case'webp':
      case'tiff':
      case'tif':
        return `image/${format}`;
      case'mp4':
      case'3gp':
      case'mkv':
      case'avi':
      case'mov':
      case'ogg':
      case'wav':
      case'wmv':
      case'flv':
      case'webm':
      case'mpeg':
      case'mxf':
      case'ts':
      case'vob':
      case'f4v':
      case'm4v':
      case'asf':
      case'm2ts':
        return `video/${format}`;
      default:
        return "";
    }
  };

  checkMediaFormat=(format:string)=>{
    switch(format){
      case'jpg':
      case'bmp':
      case'eps':
      case'gif':
      case'heic':
      case'ico':
      case'jpeg':
      case'nef':
      case'orf':
      case'png':
      case'psd':
      case'svg':
      case'jpeg 2000':
      case'webp':
      case'tiff':
      case'tif':
      case'mp4':
      case'3gp':
      case'mkv':
      case'avi':
      case'mov':
      case'ogg':
      case'wav':
      case'wmv':
      case'flv':
      case'webm':
      case'mpeg':
      case'mxf':
      case'ts':
      case'vob':
      case'f4v':
      case'm4v':
      case'asf':
      case'm2ts':
        return false
      default:
        return true
    }
  }
  handleRemove = (index: number,e:any) => {
    this.setState({ file: [], uploadedFiles: [], uploadedFilesAll: [] ,failedFiles: [],progress: [],status: [], mediaErr: "", durationValid: false });
  };
  updateVideoDetails = () => {
    const videoElement = document.getElementById(
      "previewVideo"
    ) as HTMLVideoElement;
    if (videoElement) {
      // videoElement.addEventListener("loadedmetadata", () => {
        const blob = new Blob([videoElement.src], { type: this.state.fileName.type }); // Create a blob from the video source
        const reader = new FileReader();

        reader.onload = (event) => {
          this.setState({
            duration: `${Math.floor(videoElement.duration)} seconds`,
          });
        };

        reader.readAsArrayBuffer(blob); // Read the blob as an ArrayBuffer
      // });
    }
  };
  changeScreen=(value:any)=>{
    let screenSelected = this.state.allScreens.find((screen:any)=>{
      return screen.id === value
    })
    this.getScreensBookings(screenSelected);
    const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes?.media_format?.toLowerCase() === screenSelected.attributes?.media_format?.toLowerCase());
    this.setState({ screenSelected, amount: screenSelected.attributes.price, recentFiles: recentFiles.splice(0, 3), startDate: '', bookedSelectedId: '', endDate: '', showErrMsg: false, errMessage: "" })
  }
  getScreensDetail=()=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.getScreensApiEndPoint,token)
    if(this.getPathDetails() === "/PaymentPreview"){
      this.getScreenStatusApiCallId = requestMesg.messageId;
    } else {
      this.getScreensApiCallId = requestMesg.messageId;
    }
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getScreensFiles=()=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.getScreenFilesApiEndPoint,token)
    this.getScreenFilesApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getSavedCardList = () => {
    let token:any = getToken()
    let requestMesg = GenericApiCall(
      configJSON.validationApiContentType, 
      configJSON.validationApiMethodType, 
      configJSON.SavedAccountEndPoint,
      token)
    this.getSavedCardListId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getScreensBookings=(screenSelected: any)=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.getScreensBookingContentType, configJSON.getScreensBookingMathodType, `${configJSON.getScreenBooking}?screen_id=${screenSelected?.id}`,token)
    
    this.getScreenBooklingsApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  
  navigateBackPaymentScreen = () => {
    let scannedQrValue:any = localStorage.getItem('scannedQrValue');
    const selectedScreen = localStorage.getItem('selectedScreen');
    const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
    const fromRejectedScreen = localStorage.getItem("from");

    if(this.getPathDetails() === "/PaymentPreview"){
      this.navigateToOtherScreen('Uploadmedia3');
    } else if(scannedQrValue || selectedScreen || selectedActiveScreen) {
      this.navigateToOtherScreen('LocalListingAds');
    } else if(fromRejectedScreen) {
      this.navigateToOtherScreen('LocalListingFiles');
    } else {
      this.navigateToOtherScreen('HomeDashboard');
    }
  }

  checkMaxLength = () => {
    let token = getToken();
    token = token ? token : ''

    let bodyData = new FormData();
    bodyData.append("[id]", this.state.bookedSelectedId);

    let requestMesg = GenericApiCall('', configJSON.postCreationAPiMethod, configJSON.getScreenFilesLengthApiEndPoint, token, bodyData)
    this.getScreenFilesLengthApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg);
  };

  handleContinuePayment = () => {
    if(this.getPathDetails() === "/PaymentPreview") {
      this.setState({ loader: true })
      this.getScreensDetail();
    } else if (this.state.screenSelected?.attributes?.interactive) {
      this.navigateToOtherScreen('Uploadmedia3', this.state);
    } else {
      this.checkMaxLength();
    }
  }

  handleCancel = () => {
    let scannedQrValue:any = localStorage.getItem('scannedQrValue');
    const selectedScreen = localStorage.getItem('selectedScreen');
    const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
    const fromRejectedScreen = localStorage.getItem("from");

    if(scannedQrValue || selectedScreen || selectedActiveScreen) {
      this.navigateToOtherScreen('LocalListingAds');
    } else if(fromRejectedScreen) {
      this.navigateToOtherScreen('LocalListingFiles');
    } else {
      this.navigateToOtherScreen('HomeDashboard');
    }
  }

  handleDisableContinue = () => {
    return this.getPathDetails() === "/PaymentPreview" ? false : this.isDisabledProceedUpload()
  }

  handleNavigationLocalStorage = (screenName?:string,params?:any) => {
    if(screenName === "PaymentPreview" || screenName === "Preview"){
      let ImageName = params;
      ImageName.screen = screenName;

      let paymentD = { ...this.state }
      paymentD.anchorEl = null;
      paymentD.anchorEl2 = null;

      if(params.from === "recentFiles"){
        const typeSplit = params.file.attributes.file_name.split('.');

        const target = {
          type: `${params.file.attributes.media_format.toLowerCase()}/${typeSplit[typeSplit.length - 1]}`,
          preview: params.file.attributes.file,
          name: params.file.attributes.file_name,
          size: params.file.attributes.file_size,
          screen_file_id: params.file.attributes.id,
        }
        
        localStorage.setItem('uploadImage',JSON.stringify(target))
      } else {
        if(ImageName.type.includes('video')){
          ImageName.preview = this.state.uploadedFilesAll[0]?.mp4_url;
        } else {
          ImageName.preview = this.state.uploadedFilesAll[0]?.converted_image_url;
        }
        localStorage.setItem('uploadImage',JSON.stringify(ImageName))
      }
      localStorage.setItem('PaymentScreen', JSON.stringify(paymentD))
    }

    if(screenName === "Uploadmedia3"){
      let paymentData = params;
      paymentData.screen = screenName;
      localStorage.setItem('PaymentScreen', JSON.stringify(paymentData))
    }
  }

  getImageUrl = (file: any) => {
    return this.state.uploadedFilesAll.length ? this.state.uploadedFilesAll[0].converted_image_url : file.preview
  }

  navigateToOtherScreen(screenName?:string,params?:any){
    if(screenName){
      const linkMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
      if(screenName === "PaymentScreen"){
        localStorage.removeItem('uploadImage');
      }
      if(screenName === "HomeDashboard" || screenName === "LocalListingAds" || screenName === "LocalListingFiles" || screenName === "PaymentSuccess"){
        this.clearSelectedData()
      }
      if(params){
        this.handleNavigationLocalStorage(screenName, params);
      }

      const raiseMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      
      if(this.state.file){
        raiseMsg.addData("file", this.state.file)
      }

      linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      linkMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg)
      this.send(linkMsg);
    }
    else{
      this.props.navigation.goBack();
    }
  }
  async componentDidMount(): Promise<void> {
    let file:any = localStorage.getItem('uploadImage');
    let paymentData:any = localStorage.getItem('PaymentScreen');
    
    this.getSavedCardList();
    
    var target = { ...this.state };
    if(!target.userDetails) {
      this.getUserDetailsValue();
    }
    
    if(paymentData){
      target = { ...JSON.parse(paymentData), openScannedModal: false }
    }

    if(!target.screenSelected){
      this.getScreensFiles();
      this.getScreensDetail();
    }

    if(file){
      if(JSON.parse(file).screen){
        target.uploadedFiles = [JSON.parse(file)];
      } 
      target.fileName = JSON.parse(file);
    } else {
      target.uploadedFiles = [];
      target.fileName = null;
      target.showErrMsg = false;
      target.errMessage = '';
      target.mediaErr = '';
      target.failedFiles = [];
      target.status = [];
      target.progress = [];
    }

    this.setState(target);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(prevState.showErrMsg !== this.state.showErrMsg) {
      setTimeout(() => {
        this.setState({
          errMessage: "",
          showErrMsg: false,
        })
      }, 3000);
    }
  };

  isDisabledProceedUpload(){
    const { startDate, endDate, screenSelected } = this.state
    if(!startDate || !endDate || screenSelected.attributes.status === "unavailable"){
      return true;
    }
    return false;
  }

  getPathDetails(){
    return window.location.pathname;
  }

  tokenExpired=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    this.send(msg);
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };  
  
  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  acceptedInputs = (): string => {
    if(this.state.screenSelected?.attributes?.media_format) return `${this.state.screenSelected.attributes?.media_format === 'image' ? 'image/*' : 'video/*, .flv, .mkv, .3gp'}`
    else return '.nonexistenttype'
  }

  // Customizable Area End
}
