import React from "react";

import {
  AppBar,
  Button,
  Container, Divider, Grid, IconButton, Toolbar, Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides:{
    MuiButton: {
      root: {
        textTransform:'none',
        height:'3.6em',
        fontWeight:700,
        color:'white',
        '&.enabledButton':{
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.disabledButton,&.disabledButton:hover ':{
          background: 'linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)',

        },
        '&$disabled':{
          background: 'rgba(104, 104, 104, 1) !important'
        },
      }
    },
    MuiContainer:{
      'root':{
        padding:'0px !important',
        background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%) !important'
      }
    },
    MuiDivider: {
      'root': {
        backgroundColor: '#64748B'
      }
    }
}
});
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
} from "./Uploadmedia3Controller";
import { ArrowBack } from "@material-ui/icons";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetails = (fileSize: any) => {
    return (
      <>
        <div  >
          <Typography style={webStyle.header}>
            Media Format
          </Typography>
          <Typography style={webStyle.value}>
            {this.state.fileName?.type}
          </Typography>
        </div>
        <Divider style={webStyle.dividerLine} />

        <div >
          <Typography style={webStyle.header}>File Size</Typography>
          <Typography style={webStyle.value} data-test-id="media-size">
            {fileSize}
          </Typography>
        </div>
      </>
    )
  }

  handleRenderDetails = (duration: any, fileSize: any) => {
    return (
      <div className="child2" style={{ marginLeft: '8px' }}>
      {
        duration != '' && this.state.fileName?.type.includes('video') ? (
          <>
            {this.renderDetails(fileSize)}
            <Divider style={webStyle.dividerLine} />
            {
              this.state.fileName.type.includes('video') &&
              <>
                <div id='duration'>
                  <Typography style={webStyle.header}>Duration</Typography>
                  <Typography style={webStyle.value}>
                    {duration}
                  </Typography>
                </div>
                <Divider style={webStyle.dividerLine} />
              </>
            }
          </>
        ) : !this.state.fileName?.type.includes('video') && (
            this.renderDetails(fileSize)
        ) 
      }
    </div>
    )
  }

  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    const { duration }: any = this.state;

    let fileSize;
    if (typeof this.state?.fileName?.size === "string") {
      fileSize = this.state.fileName.size;
    } else if (this.state?.fileName?.type?.includes('video')) {
      fileSize = `${(this.state?.fileName?.size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      fileSize = `${(this.state?.fileName?.size / 1024).toFixed(2)} KB`;
    }

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"} style={{ height: "100%", overflow: "auto" }}>
          <Grid container spacing={1} style={{ 
            top: '0',
            zIndex: 9999,
            height:'48px',
            margin: '0px -8px 4px' ,
            background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)', 
            position: 'sticky', 
            borderBottom: '1px solid #64748B',
          }}>
            <Grid item xs style={{ flexGrow: 0, padding: '4px 8px 10px', }}>
              <IconButton style={{ paddingLeft: '-1px' }} data-test-id="backArrowBtn" onClick={()=>this.navigateToOtherScreen("Uploadmedia3")}>
                <ArrowBack style={{ height: '24px', width: '24px', color: "#FFFFFF", }} />
              </IconButton>
            </Grid>
            <Grid sm xs={8} item style={{ display: 'grid', alignItems: 'center', padding: '4px 8px 10px', }}>
              <Typography style={{ fontSize: '0.8em', textTransform: 'uppercase', fontWeight: 700, textAlign: 'center', color: "#FFFFFF" }}>PREVIEW</Typography>
            </Grid>
            <Grid xs item container style={{ padding: '4 8 24', justifyItems: 'center', display: 'grid' }}>
            </Grid>
          </Grid>
        <div style={{width:'100%', paddingBottom: "70px"}}>
        
       {this.state.fileName && 
        <>
          {this.state.fileName.type.toLowerCase().includes('video') ? 
            <video
              id="previewVideo"
              controls
              playsInline
              loop
              autoPlay
              className="video-player"
              style={{ width:'100%',height:180, marginBottom: '10px' }}
              onLoadedMetadata={this.updateVideoDetails}
            >
              <source src={this.state.fileName.preview} type={"video/mp4"} />
              Your browser does not support the video tag.
            </video>
            :
            <img src={this.state.fileName.preview} style={{height:180,width:'100%',marginBottom: '8px',objectFit:'cover'}}/>
          }
        </>
        }
         <div className="container" style={{padding:'0 16px'}}>
              {this.state.fileName && <div className="child">
                <Typography style={{ fontSize: '1em', fontWeight: 700, marginBottom: '30', color: "#FFFFFF", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {this.state.fileName.name}
                </Typography>
               {this.handleRenderDetails(duration, fileSize)}
              </div>}
        </div>
      </div>
     
        </Container>
        <footer  style={{ padding:'24x',position:'fixed',bottom:'10',width:'100%' }}>
          <div style={{padding:'0 16px 16px'}}>
            <Button 
              fullWidth 
              data-test-id="preview-continue" 
              variant="contained" 
              style={{ 
                color:'black', 
                background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)" 
              }} 
              onClick={()=> this.navigateToOtherScreen('PaymentPreview')}
              disabled={this.uploadContinueButtonPreviewDisabled()}
              className={(this.uploadContinueButtonDisabled()) ? 'disabledButton' : 'enabledButton'}
            >
              Continue
            </Button>
          </div>
        </footer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  dividerLine:{
    height:'1.2px',
    margin:'12px 0px'
  },
  header:{
    color:'#F1F5F9',
    fontWeight:700,
    fontSize:'0.85em',
    marginBottom:'8'
  },
  value:{
    fontWeight:400,
    fontSize:'1em',
    marginBottom:'16',
    color: '#CBD5E1'

  },
  mainWrapper: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    height: "100px",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    
  },
  buttonStyle: {
    width: "100%",
    border: "none",
    marginTop: "40px",
   
    backgroundColor: "rgb(98, 0, 238)",
    height: "45px",
  },
};
const styles = {

    root: {
        display: "flex",
       
        minHeight: "100vh",
        flexDirection: "column",
        backgroundColor: "rgb(238, 243, 248)",
      },
      appBar: {
        height: "10vh",
        backgroundColor: "rgb(238, 243, 248)",
        
      },
      menuButton: {
        left: "-1px",

        padding: 0,
        
      },
      content: {
        flex: 1,
        height: "15vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "rgb(238, 243, 248)",
        margin: "0 25px",
        
      },
      button: {
        background: "linear-gradient(to right, #606060, #1d1c1f)",

        borderRadius: "8px",
        color: "white",
        height: "65px",
        alignSelf: "center",
        
        margin: "0 25px 25px 25px",
        fontWeight: "bold",
      },
      typography: {
        textAlign: "center",
        
        fontSize: "17px",
        margin: "3%",
        color: "rgb(133,133,133)",
      },
      button2: {
        marginBottom: "10px",
      },
      typography2: {
        textAlign: "center",
       
        fontSize: "22px",
        fontWeight: "bold",
        marginBottom: "15px",
      },
     
      '@global': {
        '*': {
          padding: 0,
          margin: 0,
        },
      },
      mainImg: {
        height: '50%',
      },
      paperElevation4: {
        boxShadow: 'none !important',
      },
      container: {
        background: 'rgb(238, 243, 248)',
      },
      child: {
        justifyContent: 'center',
        height: '73vh',
        display: 'flex',
        alignItems: 'center',
        
        flexDirection: 'column',
      },
      

};
// Customizable Area End
