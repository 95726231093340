import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { emailRegex, getToken } from "../..//utilities/src/RegExRequired";
import { GenericApiCall, checkTokenError } from "../../utilities/src/GenericApiCall";
import React, { ChangeEvent, createRef } from "react";

// Customizable Area Start
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: any;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string | ArrayBuffer | null | undefined;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  linkedIn: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  isEmailValid: boolean;
  showChangeProfilePopUp: boolean;
  errorMsg: {
    type: "error" | "warning" | "info" | "success" | "",
    msg: string
  },
  showErrMsg: boolean;
  profileImageRemoved: boolean;

  imageSrc: string | ArrayBuffer | null | undefined | any,
  closeUploadBtn: boolean;
  loading: boolean;
  imgValidation: any;
  locations: string[];
  location: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  getUserProfileApiCallId: string = '';
  updateUserProfileApiCallId: string = '';
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  uploadProfileRef: any;
  takePhotoRef: any;
  errorMsgInterval: any;
  handleFileInput: any;
  canvasRef: any;
  getLocationsApiCallId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.uploadProfileRef = createRef()
    this.handleFileInput = createRef();
    this.canvasRef = createRef();
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      imageSrc: null, // new state,
      closeUploadBtn: false,
      profile: {},
      profileImageUrl: "",
      fields: [],
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      linkedIn: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      saveEnabled: false,
      isEmailValid: true,
      showChangeProfilePopUp: false,
      errorMsg: {
        type: '',
        msg: ''
      },
      showErrMsg: false,
      profileImageRemoved: false,
      loading: false,
      imgValidation: {
        show: false,
        type: ""
      },
      locations: [],
      location: '',
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    let token = getToken();
    if (!token) {
      this.props.navigation.navigate('EmailAccountLoginBlock')
    }
    else {
      this.fetchUserProfile(token)
      this.fetchLocationListAPI(token);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  /* istanbul ignore next */
  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) {
      return this.setState({ currentProfile: { ...this.state.profile } });
    }
    if (!data) {
      return;
    }
    if (!data.attributes || !data.attributes.user_profile_data) {
      return this.setState({ currentProfile: { ...this.state.profile } });
    }

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  fetchLocationListAPI = (token: string) => {
    let requestMessage = GenericApiCall(configJSON.validationApiContentType, configJSON.httpGetMethod, configJSON.getLocationListApiEndPoint, token)
    this.getLocationsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  /* istanbul ignore next */
  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      ) {
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      } else {
        this.token = message.getData(getName(MessageEnum.SessionResponseToken));
      }

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) {
        this.token = testToken;
      }
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };
  /* istanbul ignore next */
  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) {
        return;
      }
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code &&
        dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        linkedIn: dataResponse.data.attributes.linkedIn,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(
          dataResponse.data.attributes.user_profile_data
        ).map((item) => {
          return {
            ...this.state.attributes[item as unknown as number],
            value: dataResponse.data.attributes.user_profile_data[item],
          };
        }),
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value,
          };
        }),
      });
      return true;
    }
  };

  /* istanbul ignore next */
  handleErrorResponse = (responseJson: any) => {
    let errorMsg = checkTokenError(responseJson)
    if (errorMsg.type == 'token') {
      this.setState({
        errorMsg: { type: 'error', msg: errorMsg.msg },
        loading: false,
        showErrMsg: true
      })
    }
    else {
      let msg: string[] = responseJson.errors;
      this.setState({
        errorMsg: { type: 'error', msg: msg[0] },
        showErrMsg: true,
        loading: false
      });
    }

    // Clear previous interval if it exists
    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }
    // Set new interval to hide error message
    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });
      if (errorMsg.type == 'token') {
        this.tokenExpired()
      }
    }, 5000);
  }
  /* istanbul ignore next */
  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      this.setState({
        email: responseJson.data.attributes.email,
        fullName: responseJson.data.attributes.full_name,
        city: responseJson.data.attributes.location_name ? responseJson.data.attributes.location_name : '',
        location: responseJson.data.attributes.location_name ? responseJson.data.attributes.location_name : '',
        facebook: responseJson.data.attributes.facebook ? responseJson.data.attributes.facebook : '',
        linkedIn: responseJson.data.attributes.linkedin ? responseJson.data.attributes.linkedin : '',
        profilePicture: responseJson.data.attributes.avatar ? responseJson.data.attributes.avatar : '',
        biography: responseJson.data.attributes.bio ? responseJson.data.attributes.bio : '',

      })

    } else if (apiRequestCallId === this.updateUserProfileApiCallId) {
      localStorage.setItem('showProfileUpdateSuccess', JSON.stringify(true));
      console.log("localStorage.getItem('showProfileUpdateSuccess')", localStorage.getItem('showProfileUpdateSuccess'));
      
      this.navigateBack();
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.checkSession(message)) {
      return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (responseJson.errors) {
          this.handleErrorResponse(responseJson)
        }
        else {
          this.checkOtherResponses(message);
          this.checkProfileAccount(message);
        }
        if(apiRequestCallId === this.getLocationsApiCallId) {
          const formattedStates = responseJson.states.map((data: any) => data.name);
          this.setState({
            locations: formattedStates ?? []
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  /* istanbul ignore next */
  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events

  checkRequiredFields = () => {

  };

  /* istanbul ignore next */
  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") {
        value = parseFloat(value as string);
      } else if (fieldType === "integer") {
        value = parseInt(value as string);
      } else if (fieldType === "date") {
        value = (value as Date).toDateString();
      } else if (fieldType === "boolean") {
        value = value === "true";
      } else if (fieldType === "checkbox") {
        value = !!value;
      }
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  /* istanbul ignore next */
  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (
      field.field_type === "integer" &&
      (event.key === "." || event.key === ",")
    ) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {

  };
  getCustomizableProfile = () => {

  };
  /* istanbul ignore next */
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (
        item.is_required &&
        !(item.value as string).trim() &&
        item.is_enable
      ) {
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    }
  };

  cancelChanges = () => {

  };

  /* istanbul ignore next */
  validateEmail = () => {
    setTimeout(() => {
      if (this.state.email && !emailRegex.test(this.state.email)) {
        return this.setState({ isEmailValid: false })
      } else {
        return this.setState({ isEmailValid: true })
      }
    }, 0)
  }
  /* istanbul ignore next */
  updateProfile = () => {
    let token = getToken();
    token = token ? token : ''
    this.setState({ saveEnabled: true})
    if (!this.state.fullName || this.state.fullName.length > 50 || !this.state.email || !/^[a-zA-Z\s]*$/.test(this.state.fullName) || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email) || !this.state.location || !/^[a-zA-Z0-9]*$/.test(this.state.biography) || this.state.biography.length > 150) {
      return false
    }
    if (this.state.isEmailValid) {
      let bodyData = new FormData();
      bodyData.append("[data][attributes][full_name]", this.state.fullName)
      bodyData.append("[data][attributes][email]", this.state.email)
      bodyData.append("[data][attributes][bio]", this.state.biography)
      bodyData.append("[data][attributes][location_name]", this.state.location || '')
      bodyData.append("[data][attributes][facebook]", this.state.facebook)
      bodyData.append("[data][attributes][linkedin]", this.state.linkedIn)
      if (this.state.profileImageUrl) {
        bodyData.append("[avatar]", this.state.profileImageUrl)
      }
      if (this.state.profileImageRemoved) {
        bodyData.append("[remove_photo]", JSON.stringify(true))
      }
      this.setState({ loading: true })
      let requestMesg = GenericApiCall('', configJSON.httpPutMethod, configJSON.updateUserProfileApiEndPoint, token, bodyData)
      this.updateUserProfileApiCallId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id, requestMesg)
    }
  };

  showValidationMessage = (type: string) => {
    this.setState({
      imgValidation: { show: true, type },
      showChangeProfilePopUp: false,
    });
    setTimeout(() => {
      this.setState({ imgValidation: { show: false, type: "" } });
    }, 3000);
  };

  getImage = (event: ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,) => {
    let fileName: any = event.target.files && event.target.files[0];
    if (fileName?.type) {
      const unsupportedTypes = ["svg", "tiff", "gif"];
      for (const type of unsupportedTypes) {
        if (fileName.type.includes(type)) {
          this.showValidationMessage(type.toUpperCase());
          return;
        }
      }
    }
  
    this.setState({ profileImageUrl: fileName });


    this.setState({ profileImageUrl: fileName })
    const reader = new FileReader();
    const blob = new Blob([fileName], { type: 'text/plain' });
    reader.readAsDataURL(blob)
    reader.onload = (event) => {
      this.setState({ profilePicture: event.target?.result, showChangeProfilePopUp: false, profileImageRemoved: false })
    };
    this.clearFileInput(ref)

  }
  /* istanbul ignore next */
  clickImage = (target: any,
    ref: React.RefObject<HTMLInputElement>,) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        this.setState({ profilePicture: newUrl, showChangeProfilePopUp: false, profileImageRemoved: false, profileImageUrl: file })
      }
    }
    this.clearFileInput(ref)
  }
  /* istanbul ignore next */
  fetchUserProfile = (token: string) => {
    let requestMesg = GenericApiCall(configJSON.searchApiContentType, configJSON.httpGetMethod, configJSON.getUserProfileApiEndPoint, token)
    this.getUserProfileApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  editUserProfile = (field: string, value: string) => {
    switch (field) {
      case 'email':
        this.setState({ email: value })
        break;
      case 'fullname':
        this.setState({ fullName: value })
        break;
      case 'location':
        this.setState({ location: value })
        break;
      case 'bio':
        this.setState({ biography: value })
        break;
      case 'facebook':
        this.setState({ facebook: value })
        break;
      case 'linkedin':
        this.setState({ linkedIn: value })
        break;
      default:
        break;
    }
  }
  openPopUp = () => {
    this.setState({ showChangeProfilePopUp: true })
  }
  closePopUp = () => {
    this.setState({ showChangeProfilePopUp: false})
  }
  upsertUploadProfile = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.click();
  }

  /* istanbul ignore next */
  handleClick = () => {
    if (this.handleFileInput.current) {
      this.handleFileInput.current.click();
    }
  };

  /* istanbul ignore next */
  handleImageChange = (event : any) => {
    if (event.target.files) {
      if (event.target.files.length !== 0) {
        const file = event.target.files[0];
        const newUrl = URL.createObjectURL(file);
        this.setState({ profilePicture: newUrl, showChangeProfilePopUp: false, profileImageRemoved: false, profileImageUrl: file })
      }
    }
  };

  /* istanbul ignore next */
  clearFileInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = "";
    }
  };

  navigateBack = () => {
    this.props.navigation.goBack()
  }

  removeImage = () => {
    this.setState({ profileImageRemoved: true, profilePicture: '', profileImageUrl: '', showChangeProfilePopUp: false })

  }

  tokenExpired = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    this.send(msg);
  }
  // Customizable Area End
}
