import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { createRef } from "react";
import { GenericApiCall, checkTokenError } from "../../utilities/src/GenericApiCall";
import { getToken } from "../../utilities/src/RegExRequired";

type screenDataType = {id: number, attributes: {}} | null
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  result: string;
  preResult: string;
  open: boolean;
  openCameraValidation: boolean;
  openCameraOverConstrained: boolean;
  openCameraError: boolean;
  openInvalidQRValue: boolean;
  capturedImg: string;
  allScreens: any;
  errorMsg: any;
  screenDetails: screenDataType;
  showErrMsg: boolean;
  showCopied: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScreensApiCallId = '';
  getScreenApiCallId: string = '';
  canvasRef: any;
  videoRef: any;
  errorMsgInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      result: "No result",
      preResult: '',
      // result: '{"id":313}',
      open: true,
      openCameraValidation: false,
      openCameraOverConstrained: false,
      openCameraError: false,
      openInvalidQRValue: false,
      showCopied: false,
      capturedImg: "",
      screenDetails: null,
      // capturedImg: "https://codeskulptor-demos.commondatastorage.googleapis.com/pang/HfReHl5.jpg",
      allScreens: [],
      errorMsg: "",
      showErrMsg: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.canvasRef = createRef();
    this.videoRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {   // onPageLoad ask camera permission
    // this.handleCameraPermission();
    this.getScreensDetail();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );    
    
    if (apiRequestCallId != null) {
      if(apiRequestCallId === this.getScreensApiCallId){
        this.handleScreensApiresponse(responseJson)
      }
      if(apiRequestCallId === this.getScreenApiCallId){
        this.handleScreenApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  handleScreensApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      this.setState({ allScreens: responseJson.data });
    }
  }

  handleScreenApiResponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      this.setState({ screenDetails: responseJson.screen.data });
      if(responseJson.screen.data.attributes.status !== "unavailable"){
        this.handleClickOpen();
        this.setState({open: true})
        this.setState({ 
          result: this.state.preResult,
          openCameraValidation: false, 
          openCameraError: false, 
          openCameraOverConstrained: false 
        });
        this.handleScreenshot();
      } else {
        setTimeout(() => {
          this.setState({ 
            openInvalidQRValue: true,
            openCameraValidation: false, 
            openCameraError: false, 
            openCameraOverConstrained: false ,
          });
        }, 2000);
      }
    }
  }

  handleErrorResponse = (responseJson: any) => {
    let errorMsg = checkTokenError(responseJson)
    if (errorMsg.type == 'token') {
      this.setState({
        errorMsg: { type: 'error', msg: errorMsg.msg },
        showErrMsg: true
      })
    }
    else {
      let msg: string[] = Object.values(responseJson.errors[0]);
      this.setState({
        errorMsg: { type: 'error', msg: msg[0] },
        showErrMsg: true
      });
    }

    // Clear previous interval if it exists
    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }
    // Set new interval to hide error message
    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });
      if(errorMsg.type == 'token'){
        this.tokenExpired()
      }
    }, 3000);
  }

  tokenExpired=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    this.send(msg);
  }

  getScreensDetail=()=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.getScreensApiEndPoint,token)
    this.getScreensApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }


  getScreenDetail=(screenId: string)=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, `${configJSON.getScreensApiEndPoint}/${screenId}`,token)
    this.getScreenApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  

  handleScan = (data: any | null) => {
    if (data && !this.state.openCameraValidation && !this.state.openCameraError && !this.state.openCameraOverConstrained && !this.state.openInvalidQRValue) {
      if (data.length) {
        const parseData = this.getScreenIdFromUrl(data);
        this.setState({ preResult: data });
        this.getScreenDetail(parseData);
      } else {
        setTimeout(() => {
          this.setState({ 
            openInvalidQRValue: true,
            openCameraValidation: false, 
            openCameraError: false, 
            openCameraOverConstrained: false ,
          });
        }, 2000);
      }
    }
  };

  isValidJSON = (str: string) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, result: "No result" });
  };

  handleScannerError = (error: any) => {
    console.log("error", error);
    if (error?.name === 'OverconstrainedError') {
      this.setState({ openCameraOverConstrained: true });
    } else if(error?.message.includes("Permission denied")){
      this.setState({ openCameraValidation: true });
    } else {
      this.setState({ openCameraError: true });
    }
  };

  handleScanAgain = () => {
    this.setState({
      openInvalidQRValue: false,
    });
  }

  handleCloseCamera = () => {
    this.setState({ 
      openCameraValidation: false, 
      openCameraError: false, 
      openCameraOverConstrained: false 
    });
    window.location.reload();
  };

  getScreenIdFromUrl = (screenUrl: string) => {
    const splitData = screenUrl.split('/')
    return splitData.length ? splitData[splitData.length - 1] : ''
  }

  handleCloseQR = () => {
    this.setState({ result: "No result", open: false });
  };

  copyText = () => {
    if(this.state.result && this.state.result !== "No result"){
      // navigator.clipboard.writeText(`${window.location.origin}/PaymentScreen/${JSON.parse(this.state.result)?.id}`);
      navigator.clipboard.writeText(this.state.result);
      
      this.setState({ showCopied: true });
  
      setTimeout(() => {
        this.setState({ showCopied: false });
      }, 2000);
    }
  };

  shareContent = () => {
    navigator.share({
      // url: `/PaymentScreen/${JSON.parse(this.state.result)?.id}`,
      url: `${this.state.result}`,
    })
  };

  handleScreenshot = () => {
    const videoElement = this.videoRef.current?.querySelector("video");
    const canvas = this.canvasRef.current;
    if (videoElement && canvas) {
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL("image/png");
      this.setState({ capturedImg: imageData });
    }
  };

  navigateToOtherScreen(screenName?:string, params?:any){
    if(screenName){
      if(params){
        if(screenName === "PaymentScreen"){
          localStorage.setItem('scannedQrValue', params)
        }
      }

      const linkMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
    
      linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      this.send(linkMsg);
    }
  }

}
  // Customizable Area End

